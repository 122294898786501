(function ($) {
  $.fn.replaceOptions = function (options) {
    var self, current_value, $option;

    current_value = this.val();
    this.empty();
    self = this;

    //first element is empty
    options.unshift({ value: "", label: "" });

    $.each(options, function (index, option) {
      $option = $("<option></option>")
        .attr("value", option.value)
        .text(option.label);
      self.append($option);
    });
    this.val(current_value);
  };
})(jQuery);
