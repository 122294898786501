import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setup();

    // listen for changes on data attributes used to search
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName == "data-regional-unit"){
          if (this.autocompleteInstance != null) {
            this.autocompleteInstance.val(null).trigger('change');
            this.autocompleteInstance.select2("destroy");
          }

          this.setup();
        }
      });
    });

    this.observer.observe(this.element, { attributes: true });
  }

  setup() {
    const { clientId, regionalUnit } = this.element.dataset;

    const settings = {
      ajax: {
        url: `/clients/${clientId}/boarding_units/search?regional_unit_name=${regionalUnit}`,
        dataType: "json",
        delay: 500,
        data: (params) => {
          const query = {
            sort_by: "name",
            sort_order: "asc",
            page: params.page,
          };

          return query;
        },
        processResults: ({ boarding_units }) => {
          const results = boarding_units.map((name) => ({
            id: name,
            text: name,
          }));
          const pagination = { more: false };

          return { results, pagination };
        },
      },
    };
    this.autocompleteInstance = $(this.element)
      .select2(settings)
      .on("select2:open", this.onOpenDropdown)
      .on("select2:select", this.dispatchChangeEvent)
      .on("select2:unselect", this.dispatchChangeEvent)
      .on("select2:clear", this.dispatchChangeEvent);
  }

  onOpenDropdown = (event) => {
    //workaround to focus on search input when open dropdown menu
    const searchInput = document.querySelector("input.select2-search__field");

    if (searchInput) {
      searchInput.focus();
    }
  };

  // workaround because jquery doesn't emmit the native event change
  // see https://github.com/select2/select2/issues/1908
  dispatchChangeEvent = (event) => {
    const { target } = event;
    const nativeEvent = new Event("change", { bubbles: true });
    target.dispatchEvent(nativeEvent);
  };
}
