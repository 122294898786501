import { Controller } from "@hotwired/stimulus";

const SELECT_ALL_LABEL = "MARCAR TODOS"
const UNSELECT_ALL_LABEL = "DESMARCAR TODOS"

export default class extends Controller {

  static targets = ["button", "checkbox"]

  connect() {
    this.updateButtonLabel()
  }

  toggle = (_event) => {
    this.checkboxTargets.forEach(checkboxElement => checkboxElement.checked = !checkboxElement.checked);
    this.updateButtonLabel();
  }

  updateButtonLabel = () => {
    const newLabel = this.buttonTarget.innerText == SELECT_ALL_LABEL ? UNSELECT_ALL_LABEL : SELECT_ALL_LABEL;
    this.buttonTarget.innerText = newLabel;
  }
}
