import { Controller } from "@hotwired/stimulus";
import Toast from "../../src/toast";

const PROCESS_SITUATION_NEGADO = "Negado";
const STAGE_EM_REGULACAO = "Em regulação";
const STAGE_EM_ANALISE_SEGURADORA = "Em análise seguradora";
const STAGE_STANDBY = "Stand-by";
const STAGE_ENCERRADO = "Encerrado";

export default class extends Controller {
  static targets = ["form", "submitButton"]

  connect() {
    this.stageChange();
    this.pendingChange();
    this.processSituationChange();

    this.element.addEventListener("ajax:success", () => {
      $("#stepsFormModal").modal("toggle");

      Toast.fire({
        title: "Sinistro atualizado com sucesso",
        icon: "success",
      });

      window.location.reload(true);
    })

    this.element.addEventListener("ajax:error", (response, _status, _xhr) => {
      if (response != null) {
        Toast.fire({ title: response.error, icon: "error" });
      } else {
        Toast.fire({
          title: "Ocorreu uma falha ao salvar. Tente novamente.",
          icon: "error",
        });
      }
    })
  }

  stageChange = (_event) => {

    const value = $("#sinister_stage").val();

    $("#sinister_regulation_date_field").hide();
    $("#sinister_standby_reason_field ").hide();
    $("#sinister_insurer_analysis_date_field").hide();
    $("#sinister_closing_date_field").hide();
    $("#closing_form").hide();

    switch (value) {
      case STAGE_EM_REGULACAO:
        $("#sinister_regulation_date_field").show();
        break;
      case STAGE_EM_ANALISE_SEGURADORA:
        $("#sinister_insurer_analysis_date_field").show();
        break;
      case STAGE_STANDBY:
        $("#sinister_standby_reason_field").show();
        break
      case STAGE_ENCERRADO:
        $("#sinister_closing_date_field").show();
        $("#closing_form").show();
        break;
    }

    if (value != STAGE_STANDBY) {
      $("#sinister_standby_reason").val("");
    }

    if (value != STAGE_ENCERRADO) {
      $("#sinister_process_situation").val('').trigger('change')
    }

    if (value == STAGE_EM_REGULACAO || value == STAGE_EM_ANALISE_SEGURADORA) {
      $("#pending_fields").show();
    } else {
      $("#pending_fields").hide();
      $("#sinister_is_pending").prop('checked', false).trigger('change')
    }
  }

  pendingChange = (_event) => {
    const value = $("#sinister_is_pending").is(':checked');
    const pendingDescriptionInput = $("#sinister_pending_description");

    if (value) {
      pendingDescriptionInput.removeAttr("disabled");
    } else {
      pendingDescriptionInput.attr("disabled", "");
      $("#sinister_pending_description").val("");
    }
  }

  processSituationChange = (_event) => {
    const value = $("#sinister_process_situation").val();

    if (value == PROCESS_SITUATION_NEGADO) {
      $("#denial_reason_field").show();
    } else {
      $("#denial_reason_field").hide();
      $("#sinister_denial_reason").val("");
    }
  }
}
