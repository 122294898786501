
const findUserAttribute = (attributeName) => {
  const element = document.querySelector(
    `input[type="hidden"][name="user_attribute[${attributeName}]"]`
  );
  return element ? element.value : null;
}

class UserInfo {
  constructor() {
    this.username = findUserAttribute("name");
    this.role = findUserAttribute("role");
  }

  username() {
    this.username;
  }

  role() {
    this.role;
  }

  isAnalyst() {
    return this.role == "analyst";
  }

  isAdmin() {
    return this.role == "admin";
  }

  isClient() {
    return this.role == "client";
  }

  isInsurer() {
    return this.role == "insurer";
  }
}

export default () => new UserInfo();
