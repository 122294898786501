import { Controller } from "@hotwired/stimulus";
import { calcNumberOfYearsUntilToday} from "../../src/utils";

const DRIVER_KIND_FUNCIONARIO = "Funcionário";
const STOPPED_OPTION = "Parado";
const TRANSIT_OPTION = "Em trânsito";

export default class extends Controller {

  connect() {
  $('#theft_driver_kind').on('change', () => {
      const value = $('#theft_driver_kind').val();

      if(value == DRIVER_KIND_FUNCIONARIO) {
        $('#theft_responsable_company_time_div').show();
      } else {
        $('#theft_responsable_company_time_div').hide();
        $('#theft_responsable_company_time').val("");
      }
    });
    $('#theft_driver_kind').trigger('change');

    $('#theft_contract_risk_liberation').on('change', () => {
      const value = $('#theft_contract_risk_liberation').val();

      if(value == "true") {
        $('#which_risk_liberation').show();
      } else {
        $('#which_risk_liberation').hide();
        $('#theft_contract_which_risk_liberation').val("");
      }
    });
    $('#theft_contract_risk_liberation').trigger('change');

    $('#theft_contract_vehicle_tracker').on('change', () => {
      const value = $('#theft_contract_vehicle_tracker').val();

      if(value == "true") {
        $('#which_vehicle_tracker').show();
      } else {
        $('#which_vehicle_tracker').hide();
        $('#theft_contract_which_vehicle_tracker').val("");
      }
    });
    $('#theft_contract_vehicle_tracker').trigger('change');

    $('#theft_stopped_or_transit').on('change', () => {
      const value = $('#theft_stopped_or_transit').val();

      switch (value) {
        case STOPPED_OPTION:
          $("#stopped_location_div").show();
          $("#stopped_reason_div").show();
          $("#occurrence_daytime_div").hide();
          $("#theft_occurrence_daytime").val("");
          break;
        case TRANSIT_OPTION:
          $("#stopped_location_div").hide();
          $("#stopped_reason_div").hide();
          $("#occurrence_daytime_div").show();
          $("#theft_occurrence_stopped_reason").val("");
          break;
        default:
          $("#stopped_location_div").hide();
          $("#stopped_reason_div").hide();
          $("#occurrence_daytime_div").hide();
          $("#theft_occurrence_stopped_reason").val("");
          $("#theft_occurrence_daytime").val("");
          break;
      }
    });
    $('#theft_stopped_or_transit').trigger('change');

    $('#theft_vehicle_has_insurance').on('change', () => {
      const value = $('#theft_vehicle_has_insurance').val();

      if(value == "true") {
        $('#vehicle_insurer').show();
      } else {
        $('#vehicle_insurer').hide();
        $('#theft_vehicle_insurer').val("");
      }
    });
    $('#theft_vehicle_has_insurance').trigger('change');

    $('#theft_route_changed').on('change', () => {
      const value = $('#theft_route_changed').val();
      if(value == "true") {
        $('#route_change_authorized_div').show();
      } else {
        $('#route_change_authorized_div').hide();
      }
    });
    $('#theft_route_changed').trigger('change');

    $('#theft_horse_manufacture').on('keyup', () => {
      const age = $('#theft_horse_manufacture').val();

      if(age.length == 4) {
        $('#theft_horse_age').val(calcNumberOfYearsUntilToday(age));
        $('#theft_horse_age').trigger('change');
      } else {
        $('#theft_horse_age').val("");
        $('#theft_horse_age').trigger('change');
      }
    });
    $('#theft_horse_manufacture').trigger('keyup');

    $('#theft_cart_1_manufacture').on('keyup', () => {
      const age = $('#theft_cart_1_manufacture').val();

      if(age.length == 4) {
        $('#theft_cart_1_age').val(calcNumberOfYearsUntilToday(age));
        $('#theft_cart_1_age').trigger('change');
      } else {
        $('#theft_cart_1_age').val("");
        $('#theft_cart_1_age').trigger('change');
      }
    });
    $('#theft_cart_1_manufacture').trigger('keyup');

    $('#theft_cart_2_manufacture').on('keyup', () => {
      const age = $('#theft_cart_2_manufacture').val();

      if(age.length == 4) {
        $('#theft_cart_2_age').val(calcNumberOfYearsUntilToday(age));
        $('#theft_cart_2_age').trigger('change');
      } else {
        $('#theft_cart_2_age').val("");
        $('#theft_cart_2_age').trigger('change');
      }
    });
    $('#theft_cart_2_manufacture').trigger('keyup');

    $('#theft_cart_3_manufacture').on('keyup', () => {
      const age = $('#theft_cart_3_manufacture').val();

      if(age.length == 4) {
        $('#theft_cart_3_age').val(calcNumberOfYearsUntilToday(age));
        $('#theft_cart_3_age').trigger('change');
      } else {
        $('#theft_cart_3_age').val("");
        $('#theft_cart_3_age').trigger('change');
      }
    });
    $('#theft_cart_3_manufacture').trigger('keyup');

    $('#theft_has_event_report').on('change', () => {
      const value = $('#theft_has_event_report').val();

      if(value == "true") {
        $('#has_event_report_div').show();
      } else {
        $('#has_event_report_div').hide();
        $('#theft_event_report_responsible').val("");
        $('#theft_event_report_number').val("");
        $('#theft_event_report_state').val("");
        $('#theft_event_report_city').val("");
      }
    });
    $('#theft_has_event_report').trigger('change');

    $('#theft_auto_geocoding_enabled').on('change', function () {
      const value = $('#theft_auto_geocoding_enabled').val();

      if (value == "true") {
        $('.geocode-coordinates').hide();
      } else {
        $('.geocode-coordinates').show();
      }
    });

    $('#theft_auto_geocoding_enabled').trigger('change');
  }

  copyVehicleInfo = (event) => {
    event.preventDefault();

    const proprietario = $("#theft_horse_owner").val();
    const cpfCnpj = $("#theft_horse_document").val();
    const anoFabricacao = $("#theft_horse_manufacture").val();
    const idade = $("#theft_horse_age").val();
    const tipoVeiculo = $("#theft_vehicle_kind").val();
    const estado = $("#theft_horse_owner_state").val();
    const cidade = $("#theft_horse_owner_city").val();

    $("#theft_cart_1_owner").val(proprietario);
    $("#theft_cart_1_document").val(cpfCnpj);
    $("#theft_cart_1_manufacture").val(anoFabricacao);
    $("#theft_cart_1_age").val(idade);
    $("#theft_cart_1_kind").val(tipoVeiculo);
    $("#theft_cart_1_owner_state").val(estado).trigger("change");
    $("#theft_cart_1_owner_city").val(cidade).trigger('change');

    $("#theft_cart_2_owner").val(proprietario);
    $("#theft_cart_2_document").val(cpfCnpj);
    $("#theft_cart_2_manufacture").val(anoFabricacao);
    $("#theft_cart_2_age").val(idade);
    $("#theft_cart_2_kind").val(tipoVeiculo);
    $("#theft_cart_2_owner_state").val(estado).trigger('change');
    $("#theft_cart_2_owner_city").val(cidade).trigger("change");

    $("#theft_cart_3_owner").val(proprietario);
    $("#theft_cart_3_document").val(cpfCnpj);
    $("#theft_cart_3_manufacture").val(anoFabricacao);
    $("#theft_cart_3_age").val(idade);
    $("#theft_cart_3_kind").val(tipoVeiculo);
    $("#theft_cart_3_owner_state").val(estado).trigger("change");
    $("#theft_cart_3_owner_city").val(cidade).trigger("change");
  };
}
