import { Controller } from "@hotwired/stimulus";
import Toast from "../../src/toast";
import getUserInfo from "../../src/get_user_info.js"
import Rails from "rails-ujs";

export default class extends Controller {

  connect() {

    const currentUser = getUserInfo();

    // include delete button to gallery
    $.fancybox.defaults.btnTpl.delete = '<button data-fancybox-delete class="fancybox-button fancybox-button--delete" title="Deletar"><svg width="20" height="20" viewBox="0 0 30 30"><path style="stroke-width: 0 " d="M 10 3 L 9 4 L 5 4 L 5 6 L 19 6 L 19 4 L 15 4 L 14 3 Z M 6 7 L 6 20 C 6 21.101563 6.898438 22 8 22 L 16 22 C 17.101563 22 18 21.101563 18 20 L 18 7 Z M 9 10 L 11 10 L 11 19 L 9 19 Z M 13 10 L 15 10 L 15 19 L 13 19 Z " fill="#cccccc"/></g></svg></button>';

    let buttons = [
      "slideShow",
      "fullScreen",
      "thumbs",
      "download",
      "close",
    ];

    if (currentUser.isAdmin()) {
      buttons.splice(4, 0, 'delete');
    }

    $('[data-fancybox="gallery"]').fancybox({ buttons });

    $("body").on("click", "[data-fancybox-delete]", (_event) => {
      const element = $.fancybox.getInstance().current.opts.$orig[0];
      const id = $(element).data("attachment-id");
      const sinisterId = $(element).data("sinister-id");

      if (confirm("Você tem certeza?")) {
        Rails.ajax({
          url: `/sinisters/${sinisterId}/attachments/${id}`,
          type: "DELETE",
          dataType: "json",
          success: (data) => {
            if (data.redirect_to) {
              window.location = data.redirect_to;
              Toast.fire({ title: data.notice, icon: "success" })
            }
          },
        });
      }
    });
  }
}
