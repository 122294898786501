import { Controller } from "@hotwired/stimulus";
import IMask from "imask";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["calculationModelInput", "inputToUpdate", "cloneInput"];

  connect() {
    this.recalculateUrl = this.element.dataset.recalculateUrl;

    this.element.addEventListener("change", () => this.recalculate());
  }

  changeCalculationModel = (event) => {
    const { value } = event.target;

    if (value == "MANUAL") {
      this.calculationModelInputTargets.forEach((input) =>
        input.removeAttribute("disabled")
      );
    } else {
      this.calculationModelInputTargets.forEach((input) =>
        input.setAttribute("disabled", true)
      );
    }

    this.recalculate();
  };

  recalculate = () => {
    const data = $(this.element)
      .find("input[name!=_method], select")
      .serialize();

    Rails.ajax({
      url: this.recalculateUrl,
      type: "POST",
      data: data,
      success: (data) => {
        this.updateForm(data);
        this.updateClones();
      },
    });
  };

  updateForm = (data) => {
    this.inputToUpdateTargets.forEach((input) => {
      const inputNameSplitted = input.name.split(/[\[\]]/).filter(String);
      const field = inputNameSplitted.pop();

      if (field) {
        let value = 0

        if (!isNaN(data[field])) {
          value =  parseFloat(data[field]).toFixed(2);
        }
        input.value = "";
        input.value = value.toString();

        // trigger change to update mask
        const event = new CustomEvent("outsideChange");
        input.dispatchEvent(event);
      } else {
        console.warn(`field ${field} not found`);
      }
    });
  };

  updateClones = () => {
    this.cloneInputTargets.forEach((input) => {
      const { copyFrom } = input.dataset;
      const fromInput = document.querySelector(copyFrom);
      input.value = fromInput.value;
    });
  };

  formatToReal = (value) => {
    if (this.currencyFormatter == null) {
      this.currencyFormatter = IMask.createMask({
        mask: Number,
        scale: 2,
        signed: true,
        thousandsSeparator: ".",
        padFractionalZeros: true,
        normalizeZeros: false,
        radix: ",",
        mapToRadix: ["."],
      });
    }

    return this.currencyFormatter.resolve(value);
  };
}
