import { Controller } from "@hotwired/stimulus";

const AUTOCOMPLETE_ENDPOINTS = {
  boarding_unit: "/dashboards/boarding_units",
  client: "/dashboards/clients",
  insurer: "/dashboards/insurers",
  regional_unit: "/dashboards/regional_units",
};

export default class extends Controller {
  static targets = ["autocomplete"];

  connect() {
    this.autocompleteTargets.forEach((element) => this.setupFilter(element));
  }

  get conditionalFilters() {
    return this.autocompleteTargets.filter(
      (element) => element.dataset.conditional
    );
  }

  onChangeFilter = (_event) => {
    this.conditionalFilters.forEach((element) => {
      this.updateState(element);
    });
  };

  setupFilter = (element) => {
    const { remoteType } = element.dataset;

    let options = {};

    if (remoteType) {
      const url = AUTOCOMPLETE_ENDPOINTS[remoteType];
      options = {
        ajax: {
          url,
          dataType: "json",
          data: (params) => {
            let conditionalFilters = {};
            const requiredFilters = this.findRequiredFilters(element);

            requiredFilters.forEach((filter) => {
              const { name } = filter;
              const selectedValues = $(filter).val();

              conditionalFilters[name] = selectedValues;
            })

            return {
              ...params,
              ...conditionalFilters
            }
          }
        },
      };
    }

    const mergedOptions = { ...options, ...{ allowClear: true } };

    $(element)
      .select2(mergedOptions)
      .on("select2:select", this.onChangeFilter)
      .on("select2:unselect", this.onChangeFilter)
      .on("select2:clear", this.onChangeFilter);
  };

  reloadFilter = (element) => {
    $(element).select2("destroy");

    this.setupFilter(element);
  };

  updateState = (element) => {
    const requiredFilters = this.findRequiredFilters(element);
    const currentSelectedValuesFromRequiredFilters = requiredFilters.map(element => $(element).select2("data"))
    const selected = currentSelectedValuesFromRequiredFilters.every(selected => selected.length > 0);

    if (selected) {
      element.removeAttribute("disabled");
    } else {
      element.setAttribute("disabled", true);
    }

    this.reloadFilter(element);
  };

  findRequiredFilters = (element) => {
    const { dependsOn } = element.dataset;

    if (!dependsOn) return [];

    const dependsOnFields = dependsOn.split(",").map((item) => item.trim());
    return dependsOnFields.map((selectedName) => document.querySelector(`select[name="${selectedName}"]`));
  };
}
