import { Controller } from "@hotwired/stimulus";
import { calcNumberOfYearsBetweenTwoDates } from "../../src/utils";

export default class extends Controller {
  connect() {
    this.eventKinds = ["Avarias", "FastTrack"];

    // form rules
    $("#driver_birthdate").on("blur", () => {
      const birthdate = $("#driver_birthdate").val();
      const driverAgeInputJquery = $("#driver_age");

      if (birthdate.length == 10) {
        const eventDate = $("#event_date").val();
        driverAgeInputJquery.val(calcNumberOfYearsBetweenTwoDates(eventDate, birthdate));
        driverAgeInputJquery.trigger("change");
      } else {
        driverAgeInputJquery.val("");
        driverAgeInputJquery.trigger("change");
      }
    });
    $("#driver_birthdate").trigger("change");

    $("#sinister_event_kind").on("change", () => {
      const value = $("#sinister_event_kind").val();
      if (this.eventKinds.includes(value)) {
        $("#event_kind_category_div").show();
      } else {
        $("#event_kind_category_div").hide();
        $("#sinister_event_kind_category").val("");
      }

      if (value == "Apropriação indébita") {
        $("#missappropriation_type_div").show();
      } else {
        $("#missappropriation_type_div").hide();
        $("#sinister_missappropriation_type").val("");
      }
    });
    $("#sinister_event_kind").trigger("change");

    $("#activation_date").on("keyup", () => {
      const value = $("#activation_date").val();
      $("#caller_date").val(value);
      $("#caller_date").trigger("keyup");
    });
    $("#activation_date").trigger("keyup");

    $("#sinister_regional_unit").on("change", (event) => {
      const regionalUnit = $(event.target).val();
      document.querySelector("#sinister_boarding_unit").setAttribute("data-regional-unit", regionalUnit);
    });
  }
}
