import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import Rails from "rails-ujs";
import { DatasetController } from "chart.js";

export default class extends Controller {
  connect() {
    const { url, openAlertOnConnect } = this.element.dataset;
    this.url = url;

    if (openAlertOnConnect == "true") {
      this.openSummaryEmailQuestion();
    }
  }

  send = (_event) => this.openSummaryEmailQuestion();

  openSummaryEmailQuestion = () => {
    Swal.fire({
      icon: "question",
      titleText: "Aviso de Sinistro",
      html: "Enviar e-mail com os detalhes do sinistro para os endereços informados no cadastro do cliente?<br />Uma cópia será enviado para o endereço <strong>avisodesinistro@agiscorretora.com.br</strong>.",
      confirmButtonText: "Sim, enviar",
      cancelButtonText: "Não",
      showCancelButton: true,
      confirmButtonColor: "#024EA2",
    }).then((result) => {
      if (!result.value) return;

      Rails.ajax({
        type: "POST",
        url: this.url,
        success: (data) => {
          Swal.fire({
            icon: "success",
            html: data["message"],
            showConfirmButton: false,
            timer: 4000,
          });
        },
        error: (data) => {
          const message = data["message"] || "Falha ao enviar o email. Tente novamente.";

          Swal.fire({
            icon: "error",
            text: message,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      });
    });
  }
}

