import IMask from "imask";

export function numberToRealFormatter(unformattedValue) {
  const formatter = IMask.createMask({
    mask: "R$ num",
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ".",
        padFractionalZeros: true,
        normalizeZeros: false,
        radix: ",",
        mapToRadix: ["."],
      },
    },
  });
  return formatter.resolve(unformattedValue.toString());
};

export function floatFormatterToPercentage(unformattedValue) {
  const parsedValue = parseFloat(unformattedValue).toFixed(1).split(".");
  return parsedValue.join(",") + "%";
};
