import { Controller } from "@hotwired/stimulus";
import IMask from "imask";

export default class extends Controller {
  connect() {
    const { format, letterCase } = this.element.dataset;

    let maskSettings = {};

    if (format.includes("||")) {
      maskSettings["mask"] = format.split("||").map((mask) => ({ mask }));
    } else {
      maskSettings["mask"] = format;
    }

    if (letterCase == "upper") {
      maskSettings["prepare"] = (string) => string.toUpperCase();
    }

    this.maskInstance = new IMask(this.element, maskSettings);
  }

  disconnect() {
    if (this.maskInstance) {
      this.maskInstance.destroy();
    }
  }
}
