import { Controller } from "@hotwired/stimulus";
import Rails from "rails-ujs";


export default class extends Controller {

  static targets = [ "customDocument", "documentSelector", "newDocumentInputWrapper"]

  saveForm = (event) => {
    const form = event.target.closest("form");
    const url = form.getAttribute("action");
    const serializedForm = $(form).serializeObject();
    const requiredAttachmentsJson = JSON.stringify({
      required_attachments: serializedForm["sinister"]["required_attachments"],
    });
    const data = new FormData()
    data.append("authenticity_token", serializedForm["authenticity_token"]);
    data.append("sinister[required_attachments]", requiredAttachmentsJson);

    Rails.ajax({
      url,
      type: "PUT",
      dataType: "json",
      data,
      success: (_response) => {
        Toast.fire({
          title: "Documentos atualizados com sucesso",
          icon: "success",
        });
      },
    });
  }

  removeDocument = (event) => {
    const rowElement = event.target.closest(".row");
    rowElement.parentNode.removeChild(rowElement);
  }

  toggleDocumentInput = (event) => {
    const buttonLabel = event.target.innerText;

    if (buttonLabel === "PREDEFINIDO") {
      this.customDocumentTarget.style.display = "none";
      this.documentSelectorTarget.style.display = "block";
    } else {
      this.documentSelectorTarget.style.display = "none";
      this.customDocumentTarget.style.display = "block";
    }
  }

  addDocument = (event) => {
    const parentRow = event.target.closest(".row");
    const inputElement = parentRow.querySelector("input, select");
    const { value } = inputElement;
    const markup = `
      <div class="row">
        <div class="col-md-9">
          <div class="form-group">
            <label class="control-label">Documento</label>
            <input type="text" class="form-control" name="sinister[required_attachments][]" readonly value="${value}" />
          </div>
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-default btn-raised" data-action="attachments--modal#removeDocument">Remover</button>
        </div>
      </div>
    `;

    this.newDocumentInputWrapperTarget.insertAdjacentHTML("beforebegin", markup);

    inputElement.value = "";
  }
}
