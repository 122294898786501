import { Controller } from "@hotwired/stimulus";

const POLICY_KIND_RCTR_C = "RCTR-C";
const POLICY_KIND_RCF_DC = "RCF-DC";
const POLICY_KIND_RCTR_VI = "RCTR-VI";
const POLICY_KIND_RCTA_C = "RCTA-C";
const POLICY_KIND_TN = "Transporte Nacional";
const POLICY_KIND_TII = "Transporte Internacional Importação";
const POLICY_KIND_TIE = "Transporte Internacional Exportação";
const POLICY_KIND_RC_A = "RC Ambiental";

export default class extends Controller {

  connect() {
    this.manageFields();
  }

  manageFields = (_event) => {
    const policyKind = $("#policy_kind").val();

    this.manageDamageCoverageFields();
    this.manageRoadCleanup();
    this.manageClientParticipationFields();
    this.manageContainerCoverageFields();
    this.manageRegistrationFreeFields();
    this.setupEnvironmentalForm(policyKind);
    this.manageCoInsurerFields();
  }

  manageDamageCoverageFields = () => {
    const policyKind = $("#policy_kind").val();
    const hasDamageCoverage = $("#policy_has_damage_coverage").val();

    if (![POLICY_KIND_RCTR_C, POLICY_KIND_RCTR_VI, POLICY_KIND_RCTA_C, POLICY_KIND_TN, POLICY_KIND_TII, POLICY_KIND_TIE].includes(policyKind)) {
      $("#has_damage_coverage_div").hide();
      this.cleanDamageCoverageFields();
      return
    }

    $("#has_damage_coverage_div").show();

    if (hasDamageCoverage == "true") {
      $("#damage_coverage_div").show();
    } else {
      $("#damage_coverage_div").hide();
      this.cleanDamageCoverageFields();
    }
  }

  cleanDamageCoverageFields = () => {
    $("#policy_damage_coverage").val("");
    $("#policy_damage_limit").val("");
    $("#policy_damage_coverage_perc").val("");
    $("#policy_damage_franchise").val("");
  }

  manageRoadCleanup = () => {
    const policyKind = $("#policy_kind").val();
    const policyRoadCleanup = $("#policy_road_cleanup").val();

    if (![POLICY_KIND_RCTR_C, POLICY_KIND_RCTR_VI, POLICY_KIND_TN, POLICY_KIND_TII, POLICY_KIND_TIE].includes(policyKind)) {
      $("#road_cleanup_div").hide()
      this.cleanRoadCleanupFields();
      return
    }

    $("#road_cleanup_div").show();

    if (policyRoadCleanup == "true") {
      $("#road_cleanup_limit_div").show();
    } else {
      $("#road_cleanup_limit_div").hide();
      this.cleanRoadCleanupFields();
    }
  }

  cleanRoadCleanupFields = () => {
    $("#policy_road_cleanup_limit").val("");
  }

  manageClientParticipationFields = () => {
    const policyKind = $("#policy_kind").val();
    const policyClientParticipation = $("#policy_client_participation").val();

    if (
      ![
        POLICY_KIND_RCF_DC,
        POLICY_KIND_RCTA_C,
        POLICY_KIND_TN,
        POLICY_KIND_TII,
        POLICY_KIND_TIE,
        POLICY_KIND_TIE,
        POLICY_KIND_RC_A
      ].includes(policyKind)
    ) {
      $("#client_participation_div").hide();
      this.cleanClientParticipationFields();
    }

    $("#client_participation_div").show();

    if (policyClientParticipation == "true") {
      $("#client_participation_perc_div").show();
    } else {
      $("#client_participation_perc_div").hide();
      this.cleanClientParticipationFields();
    }
  }

  cleanClientParticipationFields = () => {
    $("#policy_client_participation_perc").val("");
    $("#policy_franchise_value").val("");
  }

  manageContainerCoverageFields = () => {
    const policyContainerCoverage = $("#policy_container_coverage").val();

    if (policyContainerCoverage == "true") {
      $("#container_limit_div").show();
    } else {
      $("#container_limit_div").hide();
      $("#policy_container_limit").val("");
    }
  }

  manageRegistrationFreeFields = () => {
    const policyKind = $("#policy_kind").val();
    const registrationFree = $("#policy_registration_free").val();

    if (![POLICY_KIND_RCF_DC, POLICY_KIND_RCTR_VI, POLICY_KIND_TN, POLICY_KIND_TII, POLICY_KIND_TIE].includes(policyKind)) {
      $("#registration_free_div").hide()
      this.cleanRegistrationFreeFields();
      return
    }

    $("#registration_free_div").show();

    if (registrationFree == "true") {
      $("#registration_free_value_div").show();
    } else {
      $("#registration_free_value_div").hide();
      this.cleanRegistrationFreeFields();
    }
  }

  cleanRegistrationFreeFields = () => {
    $("#registration_free_value").val("");
  }

  setupEnvironmentalForm = (kind) => {
    if (kind == POLICY_KIND_RC_A) {
      $(".hide-when-environmental").hide();
    } else {
      $(".hide-when-environmental").show();
    }
  }

  manageCoInsurerFields = () => {
    const value = $("#policy_has_co_insured").val();
    if (value == "true") {
      $(".policy_has_co_insured_field").show();
    } else {
      $(".policy_has_co_insured_field").hide();
      $(".policy_has_co_insured_field input").val("");
    }
  }

  syncInput = event => {
    const value = event.target.value;
    const hiddenFieldSelector = event.target.dataset.hiddenInputSelector;
    const hiddenInput = document.querySelector(hiddenFieldSelector);

    if (hiddenInput) {
      hiddenInput.value = value
    }
  }
}
