import { Controller } from "@hotwired/stimulus";

const PAGINATION_LIMIT = 20;

const SETTINGS = {
  ajax: {
    url: "/service_providers",
    dataType: "json",
    delay: 500,
    data: (params) => {
      const query = {
        name: params.term,
        sort_by: "name",
        sort_order: "asc",
        per_page: PAGINATION_LIMIT,
        page: params.page,
      };

      return query;
    },
    processResults: ({ data }) => {
      const results = data.map(({ id, name }) => ({
        id,
        text: name,
      }));
      const pagination = { more: data.length == PAGINATION_LIMIT };

      return { results, pagination };
    },
  },
  allowClear: true,
  placeholder: "Procure um prestador de serviços",
};

export default class extends Controller {
  connect() {
    this.autocompleteInstance = $(this.element).select2(SETTINGS);
  }
}
