import { Controller } from "@hotwired/stimulus";
import currency from "currency.js";

const BRL_CURRENCY_SETTINGS = {
  decimal: ",",
  separator: ".",
  symbol: "",
};

const BRL_FORMAT_REGEX = /^-?\d{1,3}(\.?\d{1,3})*,\d{2}$/;

export default class extends Controller {
  static values = {
    allowNegative: false
  }

  connect() {
    this.formatFromDecimalToBRL();
    this.bindListeners();
  }

  get value() {
    const { value } = this.element;
    let cleanValue;

    if (BRL_FORMAT_REGEX.test(value)) {
      cleanValue = value.replace(/[^\-\d,]/g, "").replaceAll(",", ".");
    } else {
      cleanValue = value.replace(/[^\-\d\.]/g, "");
    }

    if (cleanValue == "") { return 0.0 }

    try {
      return parseFloat(cleanValue);
    } catch (e) {
      console.warning(`cant parse value ${value} of field ${this.element.name}}`);
      return 0.0;
    }
  };

  setupBRLCurrency = (value, settings = BRL_CURRENCY_SETTINGS) => currency(value, settings);

  handleInputChange = (_event) => {
    const { value } = this.element;
    const cleanValue = value.replace(/[^0-9]/g, "");
    let cents = parseInt(cleanValue || "0");
    const isNegativeValue = value.split("-").length === 2;

    if (this.allowNegativeValue && isNegativeValue) {
      cents *= -1;
    }

    const currencySettingsForTyping = { ...BRL_CURRENCY_SETTINGS, fromCents: true };
    const brlCurrency = this.setupBRLCurrency(cents, currencySettingsForTyping);
    this.element.value = brlCurrency.format();
  };

  formatFromDecimalToBRL = () => {
    const brlCurrency = this.setupBRLCurrency(this.value);
    this.element.value = brlCurrency.format();
  };

  bindListeners = () => {
    this.element.addEventListener("input", this.handleInputChange);
    // sync mask if input was changed programatically
    this.element.addEventListener("outsideChange", this.formatFromDecimalToBRL);
  };
}
