import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["countrySelector", "national", "international"]
  static values = { country: String }

  connect() {
    if (this.hasCountrySelectorTarget) {
      this.countryValue = this.countrySelectorTarget.value;
    }
  }

  changeCountry = (event) => {
    const { value } = event.target;
    this.countryValue = value
  }

  countryValueChanged = (value, _previousValue) => {
    if (!value || value == "BR") {
      this.manageFieldsVisibility(this.internationalTargets, true);
      this.manageFieldsVisibility(this.nationalTargets);
    } else {
      this.manageFieldsVisibility(this.nationalTargets, true);
      this.manageFieldsVisibility(this.internationalTargets);
    }
  }

  manageFieldsVisibility = (elements, disabled = false) => {
    elements.forEach((wrapperFieldElement) => {
      const input = wrapperFieldElement.querySelector("input,select");

      if (disabled) {
        input.setAttribute("disabled", "");
        wrapperFieldElement.style.display = "none";
      } else {
        input.removeAttribute("disabled");
        wrapperFieldElement.style.display = "block";
      }
    });
  }
}
