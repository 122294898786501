import { Controller } from "@hotwired/stimulus";
import { Dropzone } from "dropzone";
import { DirectUpload } from "activestorage";

export default class extends Controller {
  static targets = ["input", "message"];
  static values = {
    maxFiles: Number,
    maxFileSize: Number,
    acceptedFiles: String,
  };

  connect() {
    this.hideFileInput();

    // build options and create dropzone instance
    this.dropzoneOptions = this.buildDropzoneOptions();
    this.dropzone = createDropzone(this.element, this.dropzoneOptions);

    this.updateMessage();
    this.bindEvents();
  }

  hideFileInput = () => {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  };

  buildDropzoneOptions = () => {
    let options = {
      url: this.url,
      autoQueue: false,
      dictDefaultMessage: "Solte os arquivos aqui para upload.",
      dictFallbackMessage: "O seu navegador não suporta uploads \"Arrastar/Soltar\".",
      dictFallbackText: "Use o formulário abaixo para upload.",
      dictFileTooBig: "Arquivo é muito grande({{filesize}}MB). Max permitido: {{maxFilesize}}MB.",
      dictInvalidFileType: "Você não pode fazer upload de arquivos desse tipo.",
      dictResponseError: "Falha ao realizar o upload. O servidor respondeu: Código {{statusCode}}.",
      dictCancelUpload: "Cancelar upload",
      dictUploadCanceled: "Upload cancelado",
      dictCancelUploadConfirmation: "Você tem certeza que deseja cancelar este upload?",
      dictRemoveFile: "Remover arquivo",
      dictMaxFilesExceeded: "Você não pode subir mais arquivos"
    };

    if (this.hasMaxFilesValue) {
      options["maxFiles"] = this.maxFilesValue;
    }

    if (this.hasMaxFileSizeValue) {
      options["maxFilesize"] = this.maxFileSizeValue;
    }

    if (this.hasAcceptedFilesValue) {
      options["acceptedFiles"] = this.acceptedFilesValue;
    }

    return options
  }

  bindEvents() {
    this.dropzone.on("addedfile", (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      });
    });

    this.dropzone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropzone.on("processing", (_file) => {
      this.submitButton.disabled = true;
    });

    this.dropzone.on("queuecomplete", (_file) => {
      this.submitButton.disabled = false;
    });
  }

  updateMessage = () => {
    const message = this.buildMessage();

    if (message) {
      this.messageTarget.innerText = message;
    } else {
      this.messageTarget.style.display = "none";
    }
  }

  buildMessage = () => {
    let message = "";

    const { maxFilesize, acceptedFiles } = this.dropzoneOptions;

    if (maxFilesize) {
      message = `Limite de ${maxFilesize}MB por arquivo.`;
    }

    if (acceptedFiles) {
      const acceptedFilesSplitted = acceptedFiles.split(",").map(extension => extension.substring(1, extension.length));
      const acceptedFileNormalized = acceptedFilesSplitted.join(", ");
      message += ` Formatos permitidos: ${acceptedFileNormalized}.`;
    }

    return message
  }

  get url() {
    return this.inputTarget.dataset["directUploadUrl"];
  }

  get form() {
    return this.element.closest("form");
  }

  get submitButton() {
    return this.form.querySelector("input[type=submit], button[type=submit]");
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.removeInput();
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    // append the new input after the original file input
    this.source.inputTarget.parentNode.insertBefore(
      input,
      this.source.inputTarget.nextSibling
    );
    return input;
  }

  removeInput() {
    if (this.hiddenInput) {
      this.hiddenInput.parentNode.removeChild(this.hiddenInput);
    }
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    const previewTemplate =
      this.file.previewTemplate.querySelector(".dz-upload");

    if (previewTemplate) {
      previewTemplate.style.width = `${progress}%`;
    }
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropzone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropzone.emit("error", this.file, error);
    this.source.dropzone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropzone.emit("success", this.file);
    this.source.dropzone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, delegate) {
  return new DirectUpload(file, url, delegate);
}

function createDropzone(element, options) {
  return new Dropzone(element, options);
}
