import dayjs from "dayjs";

const DATE_FORMAT = "DD/MM/YYYY";

export function calcNumberOfYearsUntilToday(year) {
  const currentYear = new Date().getFullYear();
  return currentYear - year;
};

export function calcNumberOfYearsBetweenTwoDates(date1, date2) {
  const date1Converted = parseDate(date1);
  const date2Converted = parseDate(date2);
  const diffInYears = date1Converted.diff(date2Converted, "years");
  return diffInYears;
}

export function parseDate(date) {
  if (typeof date === "string" || date instanceof String) {
    return dayjs(date, DATE_FORMAT);
  }

  return dayjs(date);
}

export function serializeObject(form) {
  const formData = new FormData(form);
  let extractedObjects = {};

  formData.forEach((value, key) => {
    let inputNameParts = key.split(/[\[\]]/).filter(String)
    const attributeName = inputNameParts.pop();
    const objectId = inputNameParts.pop();

    let object = extractedObjects[objectId] || {};

    extractedObjects[objectId] = { ...object, [attributeName]: value };
  })

  return Object.keys(extractedObjects).map ( key => extractedObjects[key]);
}
