import { Controller } from "@hotwired/stimulus";
import { calcNumberOfYearsUntilToday, calcNumberOfYearsBetweenTwoDates } from "../../src/utils";

export default class extends Controller {
  connect() {
    $('#accident_driver_license_date').on('blur', () => {
      const driverLicenseDateElement = $("#accident_driver_license_date");
      const driverLicenseDate = driverLicenseDateElement.val();
      const eventDate = driverLicenseDateElement.data("sinisterEventDate");

      if(driverLicenseDate.length == 10) {
        $('#accident_driver_license_time').val(calcNumberOfYearsBetweenTwoDates(eventDate, driverLicenseDate));
        $('#accident_driver_license_time').trigger('change');
      } else {
        $('#accident_driver_license_time').val("");
        $('#accident_driver_license_time').trigger('change');
      }
    });
    $('#accident_driver_license_date').trigger('keyup');

    $('#accident_horse_manufacture').on('keyup', () => {
      const age = $('#accident_horse_manufacture').val();

      if(age.length == 4) {
        $("#accident_horse_age").val(calcNumberOfYearsUntilToday(age));
        $('#accident_horse_age').trigger('change');
      } else {
        $('#accident_horse_age').val("");
        $('#accident_horse_age').trigger('change');
      }
    });
    $('#accident_horse_manufacture').trigger('keyup');

    $('#accident_cart_1_manufacture').on('keyup', () =>  {
      const age = $('#accident_cart_1_manufacture').val();

      if(age.length == 4) {
        $('#accident_cart_1_age').val(calcNumberOfYearsUntilToday(age));
        $('#accident_cart_1_age').trigger('change');
      } else {
        $('#accident_cart_1_age').val("");
        $('#accident_cart_1_age').trigger('change');
      }
    });
    $('#accident_cart_1_manufacture').trigger('keyup');

    $('#accident_cart_2_manufacture').on('keyup', () => {
      const age = $('#accident_cart_2_manufacture').val();

      if(age.length == 4) {
        $('#accident_cart_2_age').val(calcNumberOfYearsUntilToday(age));
        $('#accident_cart_2_age').trigger('change');
      } else {
        $('#accident_cart_2_age').val("");
        $('#accident_cart_2_age').trigger('change');
      }
    });
    $('#accident_cart_2_manufacture').trigger('keyup');

    $('#accident_cart_3_manufacture').on('keyup', () => {
      const age = $('#accident_cart_3_manufacture').val();

      if(age.length == 4) {
        $('#accident_cart_3_age').val(calcNumberOfYearsUntilToday(age));
        $('#accident_cart_3_age').trigger('change');
      } else {
        $('#accident_cart_3_age').val("");
        $('#accident_cart_3_age').trigger('change');
      }
    });
    $('#accident_cart_3_manufacture').trigger('keyup');

    $('#accident_has_event_report').on('change', () => {
      const value = $('#accident_has_event_report').val();

      if(value == "true") {
        $('#has_event_report_div').show();
      } else {
        $('#has_event_report_div').hide();
        $('#accident_event_report_responsible').val("");
        $('#accident_event_report_number').val("");
      }
    });
    $('#accident_has_event_report').trigger('change');

    $('#accident_auto_geocoding_enabled').on('change', () => {
      const value = $('#accident_auto_geocoding_enabled').val();

      if (value == "true") {
        $('.geocode-coordinates').hide();
      } else {
        $('.geocode-coordinates').show();
      }
    });

    $('#accident_auto_geocoding_enabled').trigger('change');
  }

  copyVehicleInfo = (event) => {
    event.preventDefault();

    const proprietario = $("#accident_horse_owner").val();
    const cpfCnpj = $("#accident_horse_document").val();
    const anoFabricacao = $("#accident_horse_manufacture").val();
    const idade = $("#accident_horse_age").val();
    const tipoVeiculo = $("#accident_vehicle_kind").val();
    const estado = $("#accident_horse_owner_state").val();
    const cidade = $("#accident_horse_owner_city").val();

    $("#accident_cart_1_owner").val(proprietario);
    $("#accident_cart_1_document").val(cpfCnpj);
    $("#accident_cart_1_manufacture").val(anoFabricacao);
    $("#accident_cart_1_age").val(idade);
    $("#accident_cart_1_kind").val(tipoVeiculo);
    $("#accident_cart_1_owner_state").val(estado).trigger("change");
    $("#accident_cart_1_owner_city").val(cidade).trigger("change");

    $("#accident_cart_2_owner").val(proprietario);
    $("#accident_cart_2_document").val(cpfCnpj);
    $("#accident_cart_2_manufacture").val(anoFabricacao);
    $("#accident_cart_2_age").val(idade);
    $("#accident_cart_2_kind").val(tipoVeiculo);
    $("#accident_cart_2_owner_state").val(estado).trigger("change");
    $("#accident_cart_2_owner_city").val(cidade).trigger("change");

    $("#accident_cart_3_owner").val(proprietario);
    $("#accident_cart_3_document").val(cpfCnpj);
    $("#accident_cart_3_manufacture").val(anoFabricacao);
    $("#accident_cart_3_age").val(idade);
    $("#accident_cart_3_kind").val(tipoVeiculo);
    $("#accident_cart_3_owner_state").val(estado).trigger("change");
    $("#accident_cart_3_owner_city").val(cidade).trigger("change");
  }
}
