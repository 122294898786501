import { Controller } from "@hotwired/stimulus";
import IMask from "imask";

export default class extends Controller {
  connect() {
    this.maskInstance = new IMask(this.element, {
      mask: [
        {
          mask: "(00) 0000-0000",
        },
        {
          mask: "(00) 00000-0000",
        },
      ],
    });
  }

  disconnect() {
    if (this.maskInstance) {
      this.maskInstance.destroy();
    }
  }
}
