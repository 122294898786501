import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    labels: Array,
    datasets: Object
  }

  connect() {
    const {
      total_loss,
      loss_month_percentage,
      total_sinisters,
      sinisters_month_percentage,
    } = this.datasetsValue;

    new Chart(this.element, {
      type: "bar",
      data: {
        labels: this.labelsValue,
        datasets: [
          {
            yAxisID: "yLoss",
            label: "Valor de Prejuízo",
            stack: "bar",
            data: total_loss,
            extra_data: loss_month_percentage,
            backgroundColor: "rgba(51, 122, 183, 0.2)",
            borderColor: "rgba(51, 122, 183, 1)",
            borderWidth: 1,
            datalabels: {
              color: "black",
              display: (context) => context.dataset.data[context.dataIndex] > 0,
              font: {
                weight: "bold",
              },
              formatter: (_value, context) => {
                const percent = context.dataset.extra_data[context.dataIndex];
                return floatFormatterToPercentage(percent);
              },
              anchor: "start",
              align: "end",
            },
          },
          {
            yAxisID: "yEvents",
            label: "Quantidade de Ocorrências",
            type: "line",
            data: total_sinisters,
            extra_data: sinisters_month_percentage,
            backgroundColor: "rgba(255, 127, 80, 0.2)",
            borderColor: "rgba(255, 127, 80, 1)",
            borderWidth: 1,
            fill: false,
            datalabels: {
              color: "black",
              display: (context) => context.dataset.data[context.dataIndex] > 0,
              font: {
                weight: "bold",
              },
              formatter: (_value, context) => {
                const percent = context.dataset.extra_data[context.dataIndex];
                return floatFormatterToPercentage(percent);
              },
              anchor: "end",
              align: "end",
            },
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          title: {
            display: true,
            text: "Evolução",
          },
          tooltip: {
            mode: "nearest",
            callbacks: {
              label: (context) => {
                const {
                  datasetIndex,
                  raw,
                  formattedValue,
                  dataset: { label },
                } = context;

                if (datasetIndex == 0) {
                  return `${label} ${numberToRealFormatter(raw)}`;
                }

                return `${label} ${formattedValue}`;
              },
            },
          },
          legend: {
            position: "bottom",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          yLoss: {
            position: "left",
            stacked: true,
            beginAtZero: true,
          },
          yEvents: {
            position: "right",
            beginAtZero: true,
          },
        },
      },
    });
  }
}
