import { Controller } from "@hotwired/stimulus";
import Toast from "../src/toast";

export default class extends Controller {
  static targets = ["toast"];

  connect() {
    if (this.hasToastTarget) {
      const toastOptions = this.parseToastMessage();
      this.showToast(toastOptions);
    }
  }

  parseToastMessage = () => {
    const element = this.toastTargets[0];
    const dataset = element.dataset;

    return {
      title: element.innerHTML,
      icon: dataset["toastSeverity"],
      timer: dataset["toastTimer"] || 3000,
    };
  };

  showToast = (options) => {
    Toast.fire(options);
  };
}
