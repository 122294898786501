// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import RailsUJS from "rails-ujs";
import * as ActiveStorage from "activestorage";

RailsUJS.start();
ActiveStorage.start();

import jQuery from "jquery";
import "bootstrap";
import "select2";
import "select2/dist/js/i18n/pt-BR";
import "bootstrap-slider";
import "@fancyapps/fancybox";
import "bootstrap-material-design";
import "../src/plugins/bootstrap-filestyle";
import "../src/plugins/jquery.multi-select";
import "../src/plugins/jquery.replace-options.js";
import "../src/plugins/jquery.serialize-object.min";
import Swal from "sweetalert2";
import "dropzone";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import IMask from "imask";
import "../controllers"
import Toast from "../src/toast";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// require.context("../images", true);
// require.context('../fonts', true)
// const imagePath = (name) => images(name, true)

Chart.register(ChartDataLabels);

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

global.MarkerClusterer = window.MarkerClusterer = MarkerClusterer;
global.Chart = window.Chart = Chart;
global.ChartDataLabels = window.ChartDataLabels = ChartDataLabels;
global.Swal = window.Swal = Swal;
global.IMask = window.IMask = IMask;
global.Toast = window.Toast = Toast;

$.fn.select2.defaults.set("theme", "bootstrap");
$.fn.select2.defaults.set("language", "pt-BR");
$.fn.select2.defaults.set("width", "style");

import * as ChartUtils from "../src/charts_utils.js";

window.numberToRealFormatter = ChartUtils.numberToRealFormatter;
window.floatFormatterToPercentage = ChartUtils.floatFormatterToPercentage;
