import { Controller } from "@hotwired/stimulus";
import { serializeObject } from "../javascript/src/utils";
export default class extends Controller {
  static targets = ["container","template"]

  connect() {
    const { storeInputSelector, rootSerializeKey } = this.element.dataset;
    this.storeInput = document.querySelector(storeInputSelector);
    this.rootSerializeKey = rootSerializeKey;
  }

  addContact = (event) => {
    event.preventDefault();

    // copying values from form
    const contactFields = event.target.closest(".contact-item");
    const contactKindInput = contactFields.querySelector("#contact_new_record_kind");
    const contactNameInput = contactFields.querySelector("#contact_new_record_name");
    const contactPhoneInput = contactFields.querySelector("#contact_new_record_number");

    const htmlTemplate = this.templateTarget.content.cloneNode(true);

    const contactKindNewInput = htmlTemplate.querySelector("#contact_REPLACE_ID_kind");
    const contactNameNewInput = htmlTemplate.querySelector("#contact_REPLACE_ID_name");
    const contactPhoneNewInput = htmlTemplate.querySelector("#contact_REPLACE_ID_number");

    const id = new Date().getMilliseconds();
    contactKindNewInput.setAttribute("id", `contact_${id}_kind`);
    contactKindNewInput.setAttribute("name", `contact[${id}][kind]`);
    contactKindNewInput.value = contactKindInput.value;

    contactNameNewInput.setAttribute("id", `contact_${id}_name`);
    contactNameNewInput.setAttribute("name", `contact[${id}][name]`);
    contactNameNewInput.value = contactNameInput.value;

    contactPhoneNewInput.setAttribute("id", `contact_${id}_number`);
    contactPhoneNewInput.setAttribute("name", `contact[${id}][number]`);
    contactPhoneNewInput.value = contactPhoneInput.value;

    this.containerTarget.appendChild(htmlTemplate);

    contactNameInput.value = "";
    contactPhoneInput.value = "";
  };

  removeContact = (event) => {
    const { currentTarget } = event;
    const rowElement = currentTarget.closest(".contact-item");

    if (rowElement) {
      rowElement.parentNode.removeChild(rowElement);
    }
  };

  saveForm = (_event) => {
    const contacts = serializeObject(this.containerTarget);
    const contactsSerialized = JSON.stringify({ [this.rootSerializeKey]: contacts });
    this.storeInput.value = contactsSerialized;
  };
}
