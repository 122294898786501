
import { Controller } from "@hotwired/stimulus";
import { serializeObject } from "../../src/utils";

export default class extends Controller {

  static targets = [ "form", "nameField", "limitField" ]

  connect() {
    this.warrantyLimitHiddenInput = document.querySelector("#policy_warranty_limits");
  }

  add = () => {
    const id = new Date().getMilliseconds();
    const name = this.nameFieldTarget.value;
    const limit = this.limitFieldTarget.value;
    const limitUnformatted = limit.replaceAll(".", "").replaceAll(",", ".");

    const html = `
      <div class="row warranty-limit">
        <div class="col-md-4">
          <div class="form-group ">
            <label class="control-label">Nome</label>
            <input type="text" name="warranty_limit[${id}][name]" class="form-control" value="${name}" />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group ">
            <label class="control-label">Valor (R$)</label>
            <input type="text" name="warranty_limit[${id}][value]" class="form-control" data-controller="masks--currency" value="${limitUnformatted}" />
          </div>
        </div>

        <div class="col-md-4">
          <button type="button" class="btn btn-danger" data-action="policies--warranty-limit#remove">Remover</button>
        </div>
      </div>
    `;

    this.formTarget.insertAdjacentHTML("beforeend", html)

    // cleaning up form
    this.nameFieldTarget.value = "";
    this.limitFieldTarget.value = "";
    // refresh mask
    this.limitFieldTarget.dispatchEvent(new Event("input"));
  };

  remove = (event) => {
    const { currentTarget } = event;
    const rowElement = currentTarget.closest(".warranty-limit");

    if (rowElement) {
      rowElement.parentNode.removeChild(rowElement);
    }
  };

  save = (_event) => {
    const warranties = serializeObject(this.formTarget);
    const json = JSON.stringify({ warranty_limit: warranties });
    this.warrantyLimitHiddenInput.value = json;
  };
}
