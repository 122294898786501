import { Controller } from "@hotwired/stimulus";
import IMask from "imask";
import dayjs from "../../src/dayjs";

const DATE_FORMAT = "DD/MM/YYYY";

const MASK_SETTINGS = {
  mask: Date,
  pattern: DATE_FORMAT,
  lazy: true,
  min: new Date(1900, 0, 1),
  max: new Date(2050, 0, 1),
  format: (date) => dayjs(date).format(DATE_FORMAT),
  parse: (str) => dayjs(str, DATE_FORMAT, true),
  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: 2050,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
    },
  },
};

export default class extends Controller {
  connect() {
    this.maskInstance = new IMask(this.element, MASK_SETTINGS);
  }

  disconnect() {
    if (this.maskInstance) {
      this.maskInstance.destroy();
    }
  }
}
