import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "mapWrapper" ]

  connect() {
    const modalId = this.element.getAttribute("id");
    const { lat, lng } = this.mapWrapperTarget.dataset;
    const coordinates = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    $(`#${modalId}`).on("shown.bs.modal", () => {
      this.map = new google.maps.Map(this.mapWrapperTarget, {
        zoom: 10,
        center: coordinates,
      });
      this.marker = new google.maps.Marker({
        position: coordinates,
        map: this.map,
      });
    });
  }
}
