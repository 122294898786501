import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["input", "colorsPallet"]

  connect() {
    const color = this.inputTarget.value;
    const colorPalletItem = this.colorsPalletTarget.querySelector('li[data-color="' + color.toLowerCase() + '"]');

    if (colorPalletItem) {
      colorPalletItem.classList.add("active");
    }
  }
  selectColor = (event) => {
    event.preventDefault();

    const { color } = event.target.dataset;
    const colorPalletItemSelected = this.colorsPalletTarget.querySelector(".active");

    if (colorPalletItemSelected) {
      colorPalletItemSelected.classList.remove("active");
    }

    event.target.classList.add("active");
    this.inputTarget.value = color;
    this.setColor(color);
  }

  setCustomColor = (event) => {
    const { value } = event.target;
    const colorItemCurrentSelected = this.colorsPalletTarget.querySelector(".active");

    if (colorItemCurrentSelected) {
      colorItemCurrentSelected.classList.remove("active");
    }

    if (value.length === 7) {
      this.setColor(value);
    }
  }

  setColor = (color) => {
    $(".page-header--primary, .navbar.navbar-agis").css("background-color", color);
  }
}
