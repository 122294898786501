import { Controller } from "@hotwired/stimulus";
import IMask from "imask";
export default class extends Controller {
  static targets = ["phonesSelect"];

  connect() {
    const phonesSelectJquery = $(this.phonesSelectTarget);
    phonesSelectJquery.select2({ tags: true });

    // setting phone mask
    phonesSelectJquery.on("select2:open", (_event) => {
      let input = this.phonesSelectTarget.parentNode.querySelector(
        ".select2-search__field"
      );

      if (this.maskInstance == null) {
        this.maskInstance = new IMask(input, {
          mask: [
            {
              mask: "(00) 0000-0000",
            },
            {
              mask: "(00) 00000-0000",
            },
          ],
        });
      }
    });

    //sync mask when close select search input
     phonesSelectJquery.on("select2:close", (_event) => this.maskInstance.updateValue() )

    $("#service_provider_tags").select2();
  }

  save = (event) => {
    event.preventDefault();

    const form = event.target.closest("form");

    // cleaning empty phones. select2 bug
    const phonesInputs = form.querySelectorAll(
      'input[name="service_provider[phones][]"]'
    );
    phonesInputs.forEach((input) => input.parentNode.removeChild(input));

    form.submit();
  };
}
