import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "focus" ]

  connect() {
    this.formStep = 1;
  }

  next = (event) => {
    event.preventDefault();
    this.setStep(this.formStep + 1);
  }

  previous = (event) => {
    event.preventDefault();
    this.setStep(this.formStep - 1);
  }

  setStep = (step) => {
    $(".step").addClass("hidden");
    $("#step-" + step).removeClass("hidden");
    this.formStep = step;

    this.tryFocus();
  }

  tryFocus = () => {
    const input = document.querySelector(`[data-focus-when-step="${this.formStep}"]`);

    if (input) {
      input.focus();
    }
  }
}
